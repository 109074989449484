/* eslint-disable */
export default {
      "share@NATIVE_SHARING_MW_LANDING_PAGE/ACTION": 0,
      "settings@cookies.footer.cookie_policy": 1,
      "onboarding@bumble.incognito.dismiss_warning.cta.cancel": 2,
      "signin@PHONE_INCORRECT": 3,
      "corp@copyright_notice": 4,
      "huggle_site@FORMS/ERRORS/REQUIRED_CAPTCHA": 5,
      "bumblesite@bff.landing.email.capture.field.cta": 6,
      "bumblesite@bff.landing.email.capture.field.email": 7,
      "bumblesite@bff.landing.email.capture.field.email.error": 8,
      "bumblesite@bff.landing.email.capture.field.firstname": 9,
      "bumblesite@bff.landing.email.capture.field.lastname": 10,
      "bumblesite@bff.landing.email.capture.form.submission.success": 11,
      "bumblesite@bff.landing.email.capture.legal.footer": 12,
      "bumblesite@bff.landing.email.capture.text": 13,
      "bumblesite@bff.landing.email.capture.title": 14,
      "bumblesite@bff.landing.section.five.column.right.cta": 15,
      "bumblesite@bff.landing.section.five.column.right.title": 16,
      "bumblesite@bff.landing.section.five.column.title": 17,
      "bumblesite@bff.landing.section.one.cta": 18,
      "bumblesite@bff.landing.section.one.header.mobile": 19,
      "bumblesite@bff.landing.section.one.subtitle": 20,
      "bumblesite@bff.landing.section.three.column.left.cta": 21,
      "bumblesite@bff.landing.section.three.column.left.heading": 22,
      "bumblesite@bff.landing.section.three.column.right.title": 23,
      "bumblesite@bff.landing.section.two.column.two.heading": 24,
      "bumblesite@bff.landing.section.two.column.two.text": 25,
      "bumblesite@bff.landing.seo.description": 26,
      "bumblesite@bff.landing.seo.title": 27,
      "bumblesite@bff.landing.sticker.alt": 28,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.CAREER": 29,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.FRIENDSHIP": 30,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.HOW_TO": 31,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.LOVE": 32,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.SUBMIT_STORY": 33,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.WELLNESS": 34,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BFF": 35,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BIZZ": 36,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_DATE": 37,
      "bumblesite@LAYOUT.FOOTER.CAREERS": 38,
      "bumblesite@LAYOUT.FOOTER.COMPANY": 39,
      "bumblesite@LAYOUT.FOOTER.EVENTS": 40,
      "bumblesite@LAYOUT.FOOTER.GUIDELINES": 41,
      "bumblesite@LAYOUT.FOOTER.HELP_CENTER": 42,
      "bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES": 43,
      "bumblesite@LAYOUT.FOOTER.PRIVACY_POLICY": 44,
      "bumblesite@LAYOUT.FOOTER.SEASONAL_DATING_GUIDES": 45,
      "bumblesite@LAYOUT.FOOTER.THEAPP": 46,
      "bumblesite@LAYOUT.FOOTER.TNC": 47,
      "bumblesite@LAYOUT.HEADER.ABOUT": 48,
      "bumblesite@LAYOUT.HEADER.AMBASSADORS": 49,
      "bumblesite@LAYOUT.HEADER.BEEHIVE": 50,
      "bumblesite@LAYOUT.HEADER.THE_SHOP": 51,
      "bumblesite@team_bumble.ceo.full_name": 52,
      "bumblesite@team_bumble.page_about.badoo_app_block.subtitle": 53,
      "bumblesite@team_bumble.page_about.badoo_app_block.text": 54,
      "bumblesite@team_bumble.page_about.badoo_app_block.title": 55,
      "bumblesite@team_bumble.page_about.bumble_app_block.subtitle": 56,
      "bumblesite@team_bumble.page_about.bumble_app_block.text.first": 57,
      "bumblesite@team_bumble.page_about.bumble_app_block.text.second": 58,
      "bumblesite@team_bumble.page_about.bumble_app_block.title": 59,
      "bumblesite@team_bumble.page_about.hero_block.title.first": 60,
      "bumblesite@team_bumble.page_about.hero_block.title.second": 61,
      "bumblesite@team_bumble.page_about.real_connections_block.title": 62,
      "bumblesite@team_bumble.page_austin.cap_metro.text": 63,
      "bumblesite@team_bumble.page_austin.cap_metro.title": 64,
      "bumblesite@team_bumble.page_austin.celebrations.text": 65,
      "bumblesite@team_bumble.page_austin.celebrations.title": 66,
      "bumblesite@team_bumble.page_austin.community_block.title": 67,
      "bumblesite@team_bumble.page_austin.dental.text": 68,
      "bumblesite@team_bumble.page_austin.dental.title": 69,
      "bumblesite@team_bumble.page_austin.disability_insurance.text": 70,
      "bumblesite@team_bumble.page_austin.disability_insurance.title": 71,
      "bumblesite@team_bumble.page_austin.eap.text": 72,
      "bumblesite@team_bumble.page_austin.eap.title": 73,
      "bumblesite@team_bumble.page_austin.eip.text": 74,
      "bumblesite@team_bumble.page_austin.eip.title": 75,
      "bumblesite@team_bumble.page_austin.family_care.text": 76,
      "bumblesite@team_bumble.page_austin.family_care.title": 77,
      "bumblesite@team_bumble.page_austin.family_planning.text": 78,
      "bumblesite@team_bumble.page_austin.family_planning.title": 79,
      "bumblesite@team_bumble.page_austin.financial.title": 80,
      "bumblesite@team_bumble.page_austin.food.text": 81,
      "bumblesite@team_bumble.page_austin.food.title": 82,
      "bumblesite@team_bumble.page_austin.hackathon.text": 83,
      "bumblesite@team_bumble.page_austin.hackathon.title": 84,
      "bumblesite@team_bumble.page_austin.happiness_block.title": 85,
      "bumblesite@team_bumble.page_austin.health_savings.text": 86,
      "bumblesite@team_bumble.page_austin.health_savings.title": 87,
      "bumblesite@team_bumble.page_austin.health_wellbeing_block.title": 88,
      "bumblesite@team_bumble.page_austin.hospital.text": 89,
      "bumblesite@team_bumble.page_austin.hospital.title": 90,
      "bumblesite@team_bumble.page_austin.insurance.text": 91,
      "bumblesite@team_bumble.page_austin.insurance.title": 92,
      "bumblesite@team_bumble.page_austin.internal_talks.text": 93,
      "bumblesite@team_bumble.page_austin.internal_talks.title": 94,
      "bumblesite@team_bumble.page_austin.learning_block.title": 95,
      "bumblesite@team_bumble.page_austin.learning_budget.text": 96,
      "bumblesite@team_bumble.page_austin.learning_budget.title": 97,
      "bumblesite@team_bumble.page_austin.life_insurance.text": 98,
      "bumblesite@team_bumble.page_austin.life_insurance.title": 99,
      "bumblesite@team_bumble.page_austin.mental_health.text": 100,
      "bumblesite@team_bumble.page_austin.mental_health.title": 101,
      "bumblesite@team_bumble.page_austin.merch.text": 102,
      "bumblesite@team_bumble.page_austin.merch.title": 103,
      "bumblesite@team_bumble.page_austin.page_title": 104,
      "bumblesite@team_bumble.page_austin.paid_time.text": 105,
      "bumblesite@team_bumble.page_austin.paid_time.title": 106,
      "bumblesite@team_bumble.page_austin.parental_leave.text": 107,
      "bumblesite@team_bumble.page_austin.parental_leave.title": 108,
      "bumblesite@team_bumble.page_austin.premium_app.text": 109,
      "bumblesite@team_bumble.page_austin.premium_app.title": 110,
      "bumblesite@team_bumble.page_austin.private_medical_insurance.text": 111,
      "bumblesite@team_bumble.page_austin.private_medical_insurance.title": 112,
      "bumblesite@team_bumble.page_austin.random_coffee.text": 113,
      "bumblesite@team_bumble.page_austin.random_coffee.title": 114,
      "bumblesite@team_bumble.page_austin.retirement_plan.text": 115,
      "bumblesite@team_bumble.page_austin.retirement_plan.title": 116,
      "bumblesite@team_bumble.page_austin.team_activities.text": 117,
      "bumblesite@team_bumble.page_austin.team_activities.title": 118,
      "bumblesite@team_bumble.page_austin.wellness_budget.text": 119,
      "bumblesite@team_bumble.page_austin.wellness_budget.title": 120,
      "bumblesite@team_bumble.page_barcelona.page.text": 121,
      "bumblesite@team_bumble.page_barcelona.page.title": 122,
      "bumblesite@team_bumble.page_benefits.card.austin.subtitle": 123,
      "bumblesite@team_bumble.page_benefits.card.austin.title": 124,
      "bumblesite@team_bumble.page_benefits.card.barcelona.coming_soon": 125,
      "bumblesite@team_bumble.page_benefits.card.barcelona.subtitle": 126,
      "bumblesite@team_bumble.page_benefits.card.barcelona.title": 127,
      "bumblesite@team_bumble.page_benefits.card.london.subtitle": 128,
      "bumblesite@team_bumble.page_benefits.card.london.title": 129,
      "bumblesite@team_bumble.page_benefits.card.moscow.subtitle": 130,
      "bumblesite@team_bumble.page_benefits.card.moscow.title": 131,
      "bumblesite@team_bumble.page_benefits.page.intro": 132,
      "bumblesite@team_bumble.page_benefits.page.title": 133,
      "bumblesite@team_bumble.page_careers.communications_block.text": 134,
      "bumblesite@team_bumble.page_careers.communications_block.title": 135,
      "bumblesite@team_bumble.page_careers.community_block.text": 136,
      "bumblesite@team_bumble.page_careers.community_block.title": 137,
      "bumblesite@team_bumble.page_careers.culture_block.text": 138,
      "bumblesite@team_bumble.page_careers.culture_block.title": 139,
      "bumblesite@team_bumble.page_careers.design_block.text": 140,
      "bumblesite@team_bumble.page_careers.design_block.title": 141,
      "bumblesite@team_bumble.page_careers.engineering_block.text": 142,
      "bumblesite@team_bumble.page_careers.engineering_block.title": 143,
      "bumblesite@team_bumble.page_careers.engineering_block.title.uppercase": 144,
      "bumblesite@team_bumble.page_careers.excellence_block.text": 145,
      "bumblesite@team_bumble.page_careers.excellence_block.title": 146,
      "bumblesite@team_bumble.page_careers.finance_block.text": 147,
      "bumblesite@team_bumble.page_careers.finance_block.title": 148,
      "bumblesite@team_bumble.page_careers.intelligence_block.text": 149,
      "bumblesite@team_bumble.page_careers.intelligence_block.title": 150,
      "bumblesite@team_bumble.page_careers.learn_more_cta": 151,
      "bumblesite@team_bumble.page_careers.legal_block.text": 152,
      "bumblesite@team_bumble.page_careers.legal_block.title": 153,
      "bumblesite@team_bumble.page_careers.marketing_block.text": 154,
      "bumblesite@team_bumble.page_careers.marketing_block.title": 155,
      "bumblesite@team_bumble.page_careers.marketing_block.title.uppercase": 156,
      "bumblesite@team_bumble.page_careers.page.header": 157,
      "bumblesite@team_bumble.page_careers.page.title": 158,
      "bumblesite@team_bumble.page_careers.product_block.text": 159,
      "bumblesite@team_bumble.page_careers.product_block.title": 160,
      "bumblesite@team_bumble.page_careers.reseach_block.text": 161,
      "bumblesite@team_bumble.page_careers.reseach_block.title": 162,
      "bumblesite@team_bumble.page_design.badoo_block.text": 163,
      "bumblesite@team_bumble.page_design.badoo_block.title": 164,
      "bumblesite@team_bumble.page_design.bumble_block.text": 165,
      "bumblesite@team_bumble.page_design.bumble_block.title": 166,
      "bumblesite@team_bumble.page_design.design_block.text": 167,
      "bumblesite@team_bumble.page_design.design_block.title": 168,
      "bumblesite@team_bumble.page_design.design_block.title.uppercase": 169,
      "bumblesite@team_bumble.page_engineering.good_block.text": 170,
      "bumblesite@team_bumble.page_engineering.good_block.title": 171,
      "bumblesite@team_bumble.page_engineering.page.header": 172,
      "bumblesite@team_bumble.page_engineering.page.intro": 173,
      "bumblesite@team_bumble.page_engineering.technology_block.text": 174,
      "bumblesite@team_bumble.page_engineering.technology_block.title": 175,
      "bumblesite@team_bumble.page_engineering.video_block.cta": 176,
      "bumblesite@team_bumble.page_engineering.video_block.text.first": 177,
      "bumblesite@team_bumble.page_engineering.video_block.text.second": 178,
      "bumblesite@team_bumble.page_engineering.video_block.title": 179,
      "bumblesite@team_bumble.page_index.ceo_message.text": 180,
      "bumblesite@team_bumble.page_index.ceo_message.title": 181,
      "bumblesite@team_bumble.page_index.cookie.banner.accept_all": 182,
      "bumblesite@team_bumble.page_index.cookie.banner.manage_options": 183,
      "bumblesite@team_bumble.page_index.cookie.banner.text": 184,
      "bumblesite@team_bumble.page_index.cookie.banner.title": 185,
      "bumblesite@team_bumble.page_index.footer.body": 186,
      "bumblesite@team_bumble.page_index.footer.links.cookie": 187,
      "bumblesite@team_bumble.page_index.footer.links.gender_gap": 188,
      "bumblesite@team_bumble.page_index.footer.links.privacy": 189,
      "bumblesite@team_bumble.page_index.footer.links.slavery": 190,
      "bumblesite@team_bumble.page_index.footer.links.terms": 191,
      "bumblesite@team_bumble.page_index.hero_block.title": 192,
      "bumblesite@team_bumble.page_index.navigation.links.about": 193,
      "bumblesite@team_bumble.page_index.navigation.links.benefits": 194,
      "bumblesite@team_bumble.page_index.navigation.links.life": 195,
      "bumblesite@team_bumble.page_index.navigation.links.teams": 196,
      "bumblesite@team_bumble.page_index.navigation.menu": 197,
      "bumblesite@team_bumble.page_index.navigation.menu.join_cta": 198,
      "bumblesite@team_bumble.page_index.navigation.open_roles": 199,
      "bumblesite@team_bumble.page_index.success_story.couple.names": 200,
      "bumblesite@team_bumble.page_index.success_story.text": 201,
      "bumblesite@team_bumble.page_index.success_story.title": 202,
      "bumblesite@team_bumble.page_life.life_block.header": 203,
      "bumblesite@team_bumble.page_life.life_block.text.first": 204,
      "bumblesite@team_bumble.page_life.life_block.text.second": 205,
      "bumblesite@team_bumble.page_life.life_block.title": 206,
      "bumblesite@team_bumble.page_life.moves_block.career.text": 207,
      "bumblesite@team_bumble.page_life.moves_block.career.title": 208,
      "bumblesite@team_bumble.page_life.moves_block.change.text": 209,
      "bumblesite@team_bumble.page_life.moves_block.change.title": 210,
      "bumblesite@team_bumble.page_life.moves_block.good.text": 211,
      "bumblesite@team_bumble.page_life.moves_block.good.title": 212,
      "bumblesite@team_bumble.page_life.moves_block.header": 213,
      "bumblesite@team_bumble.page_life.moves_block.title": 214,
      "bumblesite@team_bumble.page_life.testimonials_badoo_block.header": 215,
      "bumblesite@team_bumble.page_life.testimonials_badoo_block.title": 216,
      "bumblesite@team_bumble.page_life.testimonials_badoo_video_block.title": 217,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_first.caption": 218,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_first.location": 219,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_second.caption": 220,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_second.location": 221,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_third.caption": 222,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_third.location": 223,
      "bumblesite@team_bumble.page_life.testimonials_block.header": 224,
      "bumblesite@team_bumble.page_life.testimonials_block.title": 225,
      "bumblesite@team_bumble.page_life.values_block.accountability.text": 226,
      "bumblesite@team_bumble.page_life.values_block.accountability.title": 227,
      "bumblesite@team_bumble.page_life.values_block.first_move.text": 228,
      "bumblesite@team_bumble.page_life.values_block.first_move.title": 229,
      "bumblesite@team_bumble.page_life.values_block.growth.text": 230,
      "bumblesite@team_bumble.page_life.values_block.growth.title": 231,
      "bumblesite@team_bumble.page_life.values_block.header": 232,
      "bumblesite@team_bumble.page_life.values_block.honesty.text": 233,
      "bumblesite@team_bumble.page_life.values_block.honesty.title": 234,
      "bumblesite@team_bumble.page_life.values_block.inclusivity.text": 235,
      "bumblesite@team_bumble.page_life.values_block.inclusivity.title": 236,
      "bumblesite@team_bumble.page_life.values_block.kindness.text": 237,
      "bumblesite@team_bumble.page_life.values_block.kindness.title": 238,
      "bumblesite@team_bumble.page_london.annual_leave_block.text": 239,
      "bumblesite@team_bumble.page_london.annual_leave_block.title": 240,
      "bumblesite@team_bumble.page_london.babylon_app_block.text": 241,
      "bumblesite@team_bumble.page_london.babylon_app_block.title": 242,
      "bumblesite@team_bumble.page_london.conference_block.text.new": 243,
      "bumblesite@team_bumble.page_london.conference_block.title": 244,
      "bumblesite@team_bumble.page_london.cycle_block.text": 245,
      "bumblesite@team_bumble.page_london.cycle_block.title": 246,
      "bumblesite@team_bumble.page_london.dental_insurance.text": 247,
      "bumblesite@team_bumble.page_london.dental_insurance.title": 248,
      "bumblesite@team_bumble.page_london.double_pension_block.text": 249,
      "bumblesite@team_bumble.page_london.double_pension_block.title": 250,
      "bumblesite@team_bumble.page_london.eip.text": 251,
      "bumblesite@team_bumble.page_london.eip.title": 252,
      "bumblesite@team_bumble.page_london.food.text": 253,
      "bumblesite@team_bumble.page_london.gym_flex_block.text": 254,
      "bumblesite@team_bumble.page_london.gym_flex_block.title": 255,
      "bumblesite@team_bumble.page_london.income_protection_block.text": 256,
      "bumblesite@team_bumble.page_london.income_protection_block.title": 257,
      "bumblesite@team_bumble.page_london.mental_health_block.text": 258,
      "bumblesite@team_bumble.page_london.mental_health_block.title": 259,
      "bumblesite@team_bumble.page_london.page.title": 260,
      "bumblesite@team_bumble.page_london.parental_leave_block.text": 261,
      "bumblesite@team_bumble.page_london.parental_leave_block.title": 262,
      "bumblesite@team_bumble.page_london.payroll_block.text": 263,
      "bumblesite@team_bumble.page_london.payroll_block.title": 264,
      "bumblesite@team_bumble.page_london.private_medical_insurance.title": 265,
      "bumblesite@team_bumble.page_london.season_ticket_block.text": 266,
      "bumblesite@team_bumble.page_london.season_ticket_block.title": 267,
      "bumblesite@team_bumble.page_london.sports_block.text": 268,
      "bumblesite@team_bumble.page_london.sports_block.title": 269,
      "bumblesite@team_bumble.page_london.team_activities_block.text": 270,
      "bumblesite@team_bumble.page_london.team_activities_block.title": 271,
      "bumblesite@team_bumble.page_london.vision_vouchers_block.text": 272,
      "bumblesite@team_bumble.page_london.vision_vouchers_block.title": 273,
      "bumblesite@team_bumble.page_london.wellbeing_room_block.text": 274,
      "bumblesite@team_bumble.page_london.wellbeing_room_block.title": 275,
      "bumblesite@team_bumble.page_marketing.badoo_block.text": 276,
      "bumblesite@team_bumble.page_marketing.badoo_block.title": 277,
      "bumblesite@team_bumble.page_marketing.bumble_block.text.first": 278,
      "bumblesite@team_bumble.page_marketing.bumble_block.text.second": 279,
      "bumblesite@team_bumble.page_marketing.bumble_block.title": 280,
      "bumblesite@team_bumble.page_moscow.accident_insurance.text": 281,
      "bumblesite@team_bumble.page_moscow.accident_insurance.title": 282,
      "bumblesite@team_bumble.page_moscow.annual_leave.text": 283,
      "bumblesite@team_bumble.page_moscow.annual_leave.title": 284,
      "bumblesite@team_bumble.page_moscow.flexible_benefits.text.new": 285,
      "bumblesite@team_bumble.page_moscow.flexible_benefits.title": 286,
      "bumblesite@team_bumble.page_moscow.health_insurance.text": 287,
      "bumblesite@team_bumble.page_moscow.health_insurance.title": 288,
      "bumblesite@team_bumble.page_moscow.office_doctor.text": 289,
      "bumblesite@team_bumble.page_moscow.office_doctor.title": 290,
      "bumblesite@team_bumble.page_moscow.page.title": 291,
      "bumblesite@team_bumble.page_moscow.pharmacy_progam.text": 292,
      "bumblesite@team_bumble.page_moscow.pharmacy_progam.title": 293,
      "bumblesite@team_bumble.page_moscow.table_tennis.text": 294,
      "bumblesite@team_bumble.page_moscow.table_tennis.title": 295,
      "bumblesite@team_bumble.page_open_roles.apply_now.cta": 296,
      "bumblesite@team_bumble.page_open_roles.locations.dropdown.title": 297,
      "bumblesite@team_bumble.page_open_roles.page.title": 298,
      "bumblesite@team_bumble.page_open_roles.share_job.title": 299,
      "bumblesite@team_bumble.page_open_roles.sort_by.dropdown.title": 300,
      "bumblesite@team_bumble.page_open_roles.teams.dropdown.title": 301,
      "bumblesite@team_bumble.sharing_assets.bumble_homepage_tab.title": 302,
      "bumblesite@team_bumble.sharing_assets.bumble_life_tab.title": 303,
      "bumblesite@team_bumble.sharing_assets.hiring_block.text": 304,
      "bumblesite@team_bumble.sharing_assets.hiring_block.title": 305,
      "bumblesite@trend-of-the-year-2021.alt": 306,
      "bma_client@cookie-notification.text": 307,
      "bma_client@dialog.photo_verification.not_access.download_app": 308,
      "bma_client@download_app_android": 309,
      "bma_client@download_app_ios": 310,
      "bma_client@pages.error.not-found.description": 311,
      "bma_client@pages.faq.section.items.contact_us": 312,
      "bma_client@pages.login-password.submit": 313,
      "bma_client@pages.registration.form.submit": 314,
      "bma_client@pages.registration.form.title": 315,
      "bma_client@video_chat.error_title": 316,
      "bumble_mobile_web@company.link.faq": 317,
      "badoo_homepage@footer.slavery_act": 318
} as const;
