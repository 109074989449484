import LocalizationConstructor from '@bumble/localization';
import { IncomingMessage } from 'http';
import acceptLanguageParser from 'accept-language-parser';
import { ParsedUrlQuery } from 'querystring';
import supportedLanguageIds, { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { AppError } from './app-error';

const Localization = new LocalizationConstructor();

export function getLocale(req?: IncomingMessage, params?: ParsedUrlQuery) {
    // Priority 1. Read language from params if present
    if (params && params.lang) {
        const lang = getLocaleFromParams(params);

        if (lang) {
            return lang;
        }
        // We have a `lang` in params, but it's not valid, we must throw not-found error
        else {
            throw new AppError({
                statusCode: 404,
                message: `Unknown language code or page - ${params.lang}`,
            });
        }
    }

    if (!req) {
        return 'en';
    }

    // Priority 2. Read the accept language header
    const acceptLanguage = req.headers['accept-language'];
    if (acceptLanguage) {
        const pickedLocale = acceptLanguageParser.pick<SupportedLanguage>(
            supportedLanguageIds as unknown as SupportedLanguage[],
            acceptLanguage,
            {
                loose: true,
            }
        );

        if (pickedLocale && supportedLanguageIds.includes(pickedLocale)) {
            return pickedLocale;
        }
    }

    // fall back to English
    return 'en';
}

export function getLocaleFromParams(params?: ParsedUrlQuery) {
    const paramLang = String(params?.lang) as SupportedLanguage;
    return supportedLanguageIds.includes(paramLang) ? paramLang : null;
}

export default Localization;
